import styled from 'styled-components';

export const HeaderComponent = styled.nav`
  display: flex;
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  color: ${(props) => props.secondaryColor};
  background: ${(props) => props.primaryColor};
  border-bottom: 1px solid transparent;
  z-index: 10;
  border-color: #efefef;
  box-shadow: 0px 0px 5px 0px #00000040;

  @media screen and (max-width: 766px) {
    height: 60px;
  }
`;

export const HeaderInner = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: auto;
  z-index: 10;

  @media screen and (min-width: 767px) {
    padding: 1rem 2rem;
  }

  @media screen and (max-width: 766px) {
    padding: 0rem 1rem;
  }

  @media screen and (max-width: 400px) {
    padding: 0rem 0.2rem;
  }
`;

export const HeaderSections = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export const MobileHeader = styled(HeaderSections)`
  position: relative;
  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const DesktopHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 766px) {
    display: none;
  }
`;

export const StoreName = styled.span`
  color: ${(props) => props.fontColor};
  text-transform: uppercase;
  cursor: pointer;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;

  @media screen and (min-width: 767px) {
    font-size: 24px;
    letter-spacing: 1.4px;
    display: -webkit-box;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
    letter-spacing: 1.4px;
    display: ${(props) => (props.showStoreNameOnMobile ? `-webkit-box` : `none`)};
  }

  @media screen and (max-width: 350px) {
    max-width: 150px;
  }
`;

export const CartTotalContainer = styled.span`
  display: inline-block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  flex: 0;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
`;

// Mobile Dropdown Styles
export const MobileDropownContainer = styled.div`
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.62);
`;

export const MobileHeaderDrawer = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  width: 100%;
  height: fit-content;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background: ${(props) => props.primaryColor};
  color: ${(props) => props.secondaryColor};
  padding-top: ${(props) => props.paddingTop || 0}px;
  max-height: 100vh;
  z-index: 5;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.5);
`;

export const HeaderDrawerInner = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => `calc(100vh - ${props.heightLimiter}px)`};
`;

export const HeaderExpandable = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.07);
`;

export const HeaderSection = styled.section`
  box-sizing: border-box;
  display: ${(props) => props.display};
  width: 100%;
  ${(props) => props.border && `border-bottom: 0.5px solid rgba(255,255,255,0.07);`};
  padding: 14px 1rem;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};

  &:last-child {
    border-bottom: none;
  }
`;

export const HeaderExpandedList = styled.div`
  display: 'flex';
  flex-direction: column;
  overflow-y: auto;
`;

export const HeaderExpandedListItem = styled.span`
  display: block;
  padding: 1rem 2rem;
  font-size: 15px;
  letter-spacing: 0.3px;
  cursor: pointer;
`;

export const HeaderSectionList = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const HeaderSectionListItem = styled.div`
  display: flex;
  font-size: 15px;
  align-items: center;

  & > p {
    margin-left: 10px;
  }
`;

export const MenuItem = styled.span`
  margin: ${(props) => props.margin || `0 0 0 20px`};
  display: flex;
  font-weight: 400;
  align-items: center;
  flex: 0 0 auto;
  color: ${(props) => props.secondaryColor};
  position: relative;
  cursor: pointer;

  @media screen and (min-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 766px) {
    font-size: 15px;
  }
`;

export const HeaderIcon = styled.span`
  display: ${(props) => props.display || `inline-block`};
  margin-left: 15px;
  height: ${(props) => `${props.size}px` || '16px'};
  width: ${(props) => `${props.size}px` || '16px'};
  transform: ${(props) => (props.iconPointer ? 'rotateZ(270deg)' : 'rotateZ(90deg)')};
  position: relative;
`;

// Desktop Dropdown Styles
export const DesktopHeaderDropdown = styled.section`
  display: block;
  position: absolute;
  width: fit-content;
  top: 39px;
  left: -1rem;
  background: ${(props) => props.primaryColor || '#ffffff'};
  color: ${(props) => props.secondaryColor || '000000'};
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  min-width: 100px;
  overflow: hidden;
  padding: 0.5rem 1rem;
  max-height: 400px;
  overflow-y: auto;
  width: 250px;
`;

export const DesktopDropdownItemList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  width: 100%;
`;

export const DesktopDropdownItem = styled.span`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex: 0;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  color: ${(props) => props.secondaryColor || '#000000'};
  padding: 10px 0px;
`;

export const ShopingIconWrap = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
