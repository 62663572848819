export function OrderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 23.374}
      height={props.size || 23.374}
      viewBox="0 0 23.374 23.374"
      {...props}
    >
      <path d="M0 0h23.374v23.374H0z" fill="none" />
      <path
        d="M22.707 10.209l-2.294-4a.649.649 0 00-.339-.287l-8.093-2.97a.651.651 0 00-.444 0L3.306 5.916a.65.65 0 00-.344.288l-2.294 4a.651.651 0 00.329.93l1.879.729v4.976a.651.651 0 00.43.612l8.17 2.942a.647.647 0 00.274.061h.009a.651.651 0 00.221-.038l8.231-2.964a.651.651 0 00.43-.612v-5.027l1.737-.674a.651.651 0 00.329-.93zM11.756 4.252l6.2 2.274-6.213 2.286-6.302-2.281zM3.819 7.327l6.976 2.525-1.75 3.012-6.872-2.665zm.362 5.046l4.912 1.909a.65.65 0 00.8-.28l1.207-2.078v6.956l-6.919-2.493zm15.163 4.014l-6.94 2.5v-6.85l1.077 1.952a.651.651 0 00.805.292l5.058-1.963zm-5-3.525l-1.657-3 6.873-2.528 1.644 2.87z"
        fill={props.secondaryColor || '#000'}
      />
    </svg>
  );
}
